import { APIExperience } from "../constants";
import Http from "./http";
import moment from "moment";

export const refreshAccessTokenHandler = async (req, localType) => {
  const formData = new FormData();
  const session = JSON.parse(localStorage.getItem(localType) || "{}");
  const refresh_token = session.refresh_token;

  try {
    if (Math.floor(Date.now() / 1000) > session?.expiration) {
      if (localType === "user_stu") {
        // Get the refresh token from cookie
        formData.append("refresh_token", refresh_token);
        // formData.append("token_type", 'jwt');
        formData.append("grant_type", "refresh_token");
        formData.append("client_id", "login-service-client-id");
        await Http.POST(`${APIExperience}/oauth2/access_token/`, formData, true)
          .then((res) => {
            const username = JSON.parse(
              localStorage.getItem("user_stu") || "{}"
            ).username;
            localStorage.setItem(
              localType,
              JSON.stringify({
                ...res.data,
                username,
                expiration: moment()
                  .add(res?.data?.expires_in, "seconds")
                  .unix(),
              })
            );
            return req;
          })
          .catch((err) => {
            // eslint-disable-next-line
            throw { errors: err };
          });
      }

    } else return req;
  } catch (err) {
    console.log(err);
  }
};

export default async function actionCall(
  options,
  dispatch,
  callback,
  errorHandling
) {
  options.reqResult &&
    dispatch(
      options.reqResult.request[
        options.reqResult.name.replace("Success", "Request")
      ]()
    );
  try {
    // call the service
    return await options.service().then(res => {
      if (!res) {
        // console.log('res undef', res)
        throw new Error("Please Confirm your email and try again");

      } else if (res?.data?.error_code?.includes("username") || res?.data?.error_code?.includes("email") || res?.data?.error?.includes("invalid_grant")) {
        throw new Error(res?.data?.error_code || res?.data?.error)
      }
      else if (res?.status && res?.status !== 200) {
        // show error
        if (res?.data?.error in [])
          throw new Error(res?.data?.error)
      }
      else
        callback && callback(res);
      options.reqResult &&
        dispatch(options.reqResult.request[options.reqResult.name](res.data || res));
      return res;

    })
    // if there is callback function call it!


    // dispatch action by redux toolkit


  } catch (err) {
    // dispatch action to redux
    // use error that added "Explicitly" or replace SUCCESS with ERROR : ACTION_TYPE_SUCCESS ==> ACTION_TYPE_ERROR
    // for custom error handling
    // console.log('err :>> ', err);
    errorHandling && errorHandling(err);

    // dispatch action by redux toolkit
    options.reqResult &&
      dispatch(
        options.reqResult.request[
          options.reqResult.name.replace("Success", "Error")
        ]([err])
      );
    throw new Error(err?.data?.error)

  }
}
